.middle-center{
    height: 700px;
    width: 1440;
  justify-content: center;
  align-items: center;
  display: inline-block;
}
.section1>p{
    color: var(--blue-light, #00B3A0);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    position: absolute;
   
    float: left;
    margin-left: -25rem;
    
    
}
.section2 p {
    color: var(--blue-dark, #002248);
font-size: 42px;
font-style: normal;
font-weight: 500;
justify-content: center;
align-items: center;
position: absolute;
float: left;
display: flex;
margin-left: -25rem;
margin-top: 2rem;
text-align: left;

}
.section3 p{
color: var(--paragraph-color-h-3, #697687);
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 28px; 
justify-content: center;
position: absolute;
margin-left: -25rem;
margin-top: 10rem;
text-align: left;
}
.section4 p{
    color: #3685FB;
    display: flex;
    font-size: 32px;
    font-style: normal;
    font-weight: 550;
    line-height: 34px;
    float: left;
 
}
.section4 span {
    color: var(--blue-dark, #002248);
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 28px; 



}
.section5 p {
    color: #3685FB;
    text-align: center;
    font-family: Eksell Display;
    font-size: 32px;
    font-style: normal;
    font-weight: 550;
    line-height: 34px; 
    
  
}
.section5 span {
    color: var(--blue-dark, #002248);
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 28px; 


}
.section6 p{
    color: #3685FB;
font-size: 32px;
font-style: normal;
font-weight: 550;
line-height: 34px; 

}
.section6 span{
    color: var(--blue-dark, #002248);
text-align: center;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 28px; 


}
.section7 p{
    color: #3685FB;
font-size: 32px;
font-style: normal;
font-weight: 550;
line-height: 34px; 


}
.section7 span{
    color: var(--blue-dark, #002248);
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 28px; 


}
.section8 img{
    position: absolute;
   
}
.section30{

    justify-content: center;
    align-items: center;

   display: inline-flex;
}



