*{
    margin: 0 auto ;
    padding: 0 ;
    box-sizing: border-box;
}
.header-main{
    width: 1440px;
    height: 100px;
    background-color: 
    rgba(248, 248, 248, 1);
   
}
.header-logo>img{
    
    float: left;
    
}

.header-menu>p{
    display: inline-flex;
    margin-right: 70px;
    justify-content: center;
    align-items: center;

    margin-top: 1.1rem;
}
.header-button>button{
    width: 120px;
    border-radius: 10px;
    background-color: rgba(54, 133, 251, 1);
    height: 50px;
    float: right;
    position: relative;
    bottom: 2rem;
    border: none;
}
