.part2_img{
    width: 800px;
    height: 690px;
    margin-left: 50px;
    
    }
    .img_part2{
        width: 800px;
        height: 690px;
        background-image: url("/src/assets/Vector\ \(1\).png");
        margin-left: 400px;
        margin-top: -690px;
        position: absolute;
      
    }
    .oibek2part_text{
        position: absolute;
        margin-left: 70%;
        margin-top: -500px;
        
    }
    .oibek2part_text button{
       background-color:#3685FB;
       width: 160px;
       height: 60px;
       border-radius: 10px;
       color: aliceblue;
       border: none;
        
    }
    .oibek2part_text h2{
        color:#002248;
        
    }