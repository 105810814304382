* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.instagram-line3 {
      position: absolute;
      margin-left: 10rem;
      margin-top: 5rem;
      color: #00B3A0;
      font-size: 15px;
  }
  
  
  .instagram-line1 {
    position: absolute;
    margin-left: 10rem;
    margin-top: 6rem;
    font-weight: 100px;
    font-size: 40px;
    color: #002248;
  }
  
  .instagram-line2 {
    position: absolute;
    margin-left: 10rem;
    margin-top: 8.5rem;
    font-weight: 100px;
    font-size: 35px;
    color: #002248;
  } 

  .buttom1 {
  position: absolute;
  margin-left: 64rem;
  margin-top: 8.5rem;
  width: 150px;
  height: 50px;
  border-radius: 10px;
  background-color: #3685FB;
  font-weight: 700;
  color: white;
  border: none;
  }

  .childblock2 {
    display: flex;
    justify-content: center;
    width: 83rem; 
    height: 60rem;
  }

.Oneblock1{
   width: 70%;
   height: 375px;
   margin: 0 auto;
   background: #F3F8FF;
   border-radius: 20px;
   border: none;
}

.p-text1 {
  position: absolute;
  margin-top: 85px;
  margin-left: 4rem;
  color: #00B3A0;
  font-size: 15px;
}

.p-text {
  position: absolute;
  margin-top: 114px;
  margin-left: 4rem;
  font-size: 35px;
  font-weight: 100px;
  color: #002248;
}

.Oneblock2 > span {
  position: absolute;
  margin-top: 163px;
  margin-left: 4rem;
  font-size: 35px;
  font-weight: 100px;
  color: #002248;
}

.buttom2 {
  position: absolute;
  margin-top: 16rem;
  margin-left: 4rem;
  width: 150px;
  height: 50px;
  border-radius: 10px;
  background-color: #3685FB;
  font-weight: 700;
  color: white;
  border: none;
}

.Oneblock3 > img {
  position: absolute;
  margin-left:25rem;
  margin-top: 14px;
}
  
.footer {
  width: 430px;
  margin: 300px;
}

.logo {
  margin-top: -13rem;
  margin-left: -262px;
}

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -33px;
  gap: 50px;
  font-size: 15px;
  color: #00481e;
}

.logo-inst-face-pay {
  display: flex;
  margin-top: -2rem;
  margin-left: 47rem;
  gap: 15px;
}

.line {
  width: 963px;
  margin-top: 1rem;
  margin-left: -9rem; 
  border-top: 1px solid rgb(2, 244, 79); 
}

.goodbye {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 28px;
  font-size: 14px;
}
