.middle{
    width: 1440px;
    height: 750px;
    background-color: 
    rgba(248, 248, 248, 1);
  position: relative;
    justify-content: center;
    align-items: center;
}
.middle-block>img{
   float: left;
   display: flex;
 justify-content: centter;
 align-items: center;
}
.middle-text>p{
    font-weight: 500;
    color: 
    rgba(0, 179, 160, 1);

  position: absolute;
  display: flex;
  margin-top: 4rem;
  left: 60%;
}
.middle-main-text>p{
    position: absolute;
    left: 60%;
    justify-content: center;
    align-items: centter;
    display: flex;
    font-weight: 700;
    font-size: 60px;
    color: 
    rgba(0, 34, 72, 1);
    text-align: left;
    margin-top: 5rem;
}
.middle-op-text>p{
    left: 60%;
    position: absolute;
    margin-top: 17rem;
    font-weight: 400;
    font-size: 16px;
    color: rgba(105, 118, 135, 1);
    text-align: left;
}
.middle-button>button{
    left: 60%;
    position: absolute;
    margin-top: 25rem;
    width: 170px;
    background-color: rgba(54, 133, 251, 1);
    height: 60px;
    font-weight: 700;
    font-size: 16px;
    color: white;
    border: none;
    border-radius: 10px;
}
.request{
    width: 980px;
    height: 144px;
    top: 680px;
    align-items: flex-end;
    position: absolute;
    justify-content: center;
    align-items: center;
    background-color: white;
    box-shadow: 0 0 5px;
    border-radius: 15px;
    left: 230px;
}
.request-block>p{
    display: inline-flex;
    padding-right: 15%;
    margin-top: 40px;
    color: 
    rgba(0, 34, 72, 1);
    font-weight: 700;
    font-size: 20px;
}
.request-op>p{
    display: inline-flex;
    padding-right: 18%;
    margin-top: 1rem;
    margin-right: 2rem;
}
.request-button>button{
    background-color: rgba(54, 133, 251, 1);
    border: none;
    width: 210px;
    height: 143.84px;
    border-radius: 1px 15px 15px 1px;
    float: right;
    position: relative;
    bottom: 6.55rem;
    position: relative;
    color: white;
    font-weight: 700;
}
