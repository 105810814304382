.center{
    width: 1440px;
    height: 935px;
    background-color: rgba(255, 255, 255, 1);
    display: flex;
   justify-content: center;
   align-items: center  ;
   
}
.center-block>p{
    font-weight: 700;
    font-size: 16px;
    color: 
    rgba(0, 179, 160, 1);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center; 
    
    margin-left: 14rem;
}
.center-block>span{
    font-weight: 700;
    font-size: 42px;
    color: 
    
rgba(0, 34, 72, 1);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center; 
 margin-top: 3rem;
  margin-left: 8rem;
}
.center-block>b{
    font-weight: 400;
    font-size: 16px;
    color: 
    
    rgba(105, 118, 135, 1)
    ;

    display: flex;
    justify-content: center;
    align-items: center; 
 margin-top: 8rem;
    margin-left: 5rem;
}

.card1{
    width: 370px;
    height: 395px;
    border-radius: 10px;
    border: 2px solid rgba(248, 248, 248, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    float: left;right: 15rem;
    margin-top:10rem;
}
.card1-main>p{
    color: 
    rgba(0, 34, 72, 1);
    font-size: 20px;
    font-weight: 700;
}
.card1-main>span{
    color: rgba(105, 118, 135, 1)
    ;
    font-weight: 500;
    font-size: 16px;
    margin-top: 20px;
    position: absolute;
    text-align: center;
    display: flex;
}
.card2{
    width: 370px;
    height: 395px;
    border-radius: 10px;
    border: 2px solid rgba(248, 248, 248, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    position: absolute;
    margin-left: 10rem;margin-top: 10rem;
}
.card2-main>p{
    color: 
    rgba(0, 34, 72, 1);
    font-size: 20px;
    font-weight: 700;
    margin-top: -5px;
}
.card2-main>span{
    color: rgba(105, 118, 135, 1)
    ;
    font-weight: 500;
    font-size: 16px;
    margin-top: 15px;
    position: absolute;
    text-align: center;
    display: flex;
}
.card3{
    width: 370px;
    height: 395px;
    border-radius: 10px;
    border: 2px solid rgba(248, 248, 248, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    position: absolute;
    margin-left: 35rem;
    margin-top: 10rem; 
}

.card3-main>p{
    color: 
    rgba(0, 34, 72, 1);
    font-size: 20px;
    font-weight: 700;
    margin-top: px;
}
.card3-main>span{
    color: rgba(105, 118, 135, 1)
    ;
    font-weight: 500;
    font-size: 16px;
    margin-top: 15px;
    position: absolute;
    text-align: center;
    display: flex;
}