
.block1_text{
    display: flex;
    flex-direction: column;
    justify-content: left;
    justify-content: space-between;
    gap: 5px;
}
/* .block_oibek{
   
} */
.block1{
    /* margin-left: 50px; */
    display: flex;
    flex-direction: column;
    gap: 20px;
    
}
.block1_btn{
    display: flex;
    margin-left: 95%;
    gap: 5px;
}
.block1_btn button{
    width: 30px;
    height: 30px;
    /* margin-left: 90%; */
}
.block1 h3{
    color: #00B3A0;
}
.block1_img{
    display: flex;
    /* justify-content: center; */
    text-align: center;
    gap: 20px;
}
img{
    border-radius: 10px;
}