.MiddleBlockTravel{
    background-color: #F8F8F8;
    width: 1440px;
    height: 699px;


}
.section9>p {
    color: var(--blue-light, #00B3A0);
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 18px; 
position: absolute;
margin-left: 43rem;
margin-top: 1rem;
}
.section10 p {
    color: var(--blue-dark, #002248);
font-size: 42px;
font-style: normal;
font-weight: 500;
line-height: 52px; 
text-transform: capitalize;
position: absolute;
margin-left: 26rem;
margin-top: 2rem;
}
.section11>p {
    color: var(--paragraph-color-h-3, #697687);
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 28px; 
position: absolute;
margin-left: 30rem;
margin-top: 7rem;
}
.section14 img {
  margin-top: 10rem;
  margin-left: -42rem;
  position: absolute;
}
.section17 {
    border-radius: 10px;
background: #FFF;
width: 500px;
height: 230px;
position: absolute;
margin-top: 18rem;
margin-left: 30rem;
}
.section17 p {
    color: var(--paragraph-color-h-3, #697687);
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 24px; 
justify-content: center;
align-items: center;
display: flex;
width: 536px; 
position: absolute;
margin-top: 4rem;  
}
.section12 p{
 color: var(--blue-dark, #002248);
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 18px; 
position: absolute;
left: 43rem;
top:74rem;
}
.section17>span{
    color: var(--blue-dark, #002248);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; 
    position: absolute;
    margin-top: 9rem;
    margin-left: -2rem;
}
.section17>b{
    color: var(--paragraph-color-h-3, #697687);
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; 
    position: absolute;
    margin-left: -1rem;
    margin-top: 10rem;
}
.section17>img{
    position: absolute;
    margin-top: 1rem;
    margin-left: orem;
}
.section16>img{
    position: absolute;
    margin-left: -0.5rem;
    margin-top: 33rem;
}
.section18>img{
border-radius: 35px;
transform: scale(0.9);
width: 70px;
position: absolute;
margin-top: 12rem;
margin-left: 0rem;
}
.section20>img{
    border-radius: 35px;
    transform: scale(0.7);
    width: 70px;
    position: absolute;  
    margin-top: 19rem;
    margin-left: 20rem;
}
.section19>img{
    border-radius: 35px;
    transform: scale(0.7);
    width: 70px;
    position: absolute;  
    margin-top: 19rem;
    margin-left:-31rem ;
}
